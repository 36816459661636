
import {
  Button,
  Icon,
  Spacer,
  Stack,
  Text,
  Tooltip,
  useBreakpointValue,
} from '@chakra-ui/react';
import theme from '../../theme';
import { ReactElement } from 'react';


type NavItemButtonProps = {
  text: string;
  tooltip: string;
  textColor: string;
  icon: ReactElement | undefined;
  isActive: boolean;
  isOpen: boolean;
  tabIndex: number;
  onClick: () => void;
}


export function NavItemButton({ ...props }: NavItemButtonProps) {
  const text = useBreakpointValue({ base: props.text, lg: props.text });

  const responsiveValues = {
    buttonPadding: {
      xs: '5',
      lg: '7',
      xl: '7',
    },
    textSize: {
      xs: 'small',
      lg: 'medium',
      xl: 'medium',
    },
  };

  return (
    <Button
      variant="ghost"
      width="full"
      maxHeight="8"
      minHeight="8"
      padding={responsiveValues.buttonPadding}
      cursor="pointer"
      bg={ props.isActive ? theme.colors.purple[100] : ''}
      onClick={props.onClick}
      _hover={{ bg: theme.colors.purple[200] }}
      // disabled={props.isDisabled}
      tabIndex={props.tabIndex}
    >
      <Stack
        direction="row"
        width="full"
        alignItems="center"
        justifyContent={props.isOpen ? 'flex-start' : 'center'}
      >
        <Tooltip
          label={props.tooltip}
          openDelay={1000}
          placement="end"
        >
          <Stack direction="row" width="full">
            {props.icon ? (
              <Icon height={6} width={6}>
                {props.icon}
              </Icon>
            ) : undefined}
            <Text
              fontSize="medium"
              textAlign={props.isOpen ? 'left' : 'center'}
              alignContent="center"
              transition="hideBelow 1s"
              fontWeight={600}
              textColor={props.textColor}
              display={props.isOpen ? 'block' : 'none'}
            >
              {text}
            </Text>
            <Spacer />
          </Stack>
        </Tooltip>
      </Stack>
    </Button>
  )
}