import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { useState } from 'react';
import { HelpCircle } from 'react-feather';
import { useSaveProjectStateMutation, useSetActiveProjectStateMutation } from '../lib/api/GraphAPI';
import { newInitialProjectState, setProjectState } from '../lib/state/slices/ProjectStateSlice';
import { useAppDispatch, useAppSelector } from '../lib/hooks';
import { useNavigate } from 'react-router-dom';

export const NewProjectModal = (props: {isOpen: boolean; onClose: () => void }) => {
  
  const [text, setText] = useState("");
  const [saveView] = useSaveProjectStateMutation();
  const [setActive] = useSetActiveProjectStateMutation();
  const state = useAppSelector((state) => state.viewReducer);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  
  const handleClose = () => {
    setText("");
    props.onClose();
  };
  
  const handleSubmit = () => {
    
    if (state.isAutosaveActive) {
      saveView(state);
    }

    const newProjectState = newInitialProjectState({ name: text, isAutosaveActive: true });
    
    dispatch(setProjectState(newProjectState));
    
    navigate('/research');
    
    saveView(newProjectState);
    setActive(newProjectState.id);
    
    handleClose();
  }
  
  return (
    <Modal isOpen={props.isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent w="1000px">
        <ModalHeader>
          <Stack direction="row" alignItems="center">
            <Text>New Project</Text>
            <Tooltip
              label="A project contains your graphs, folders and search information"
              placement="right"
            >
              <HelpCircle width={20} height={20} />
            </Tooltip>
          </Stack>
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody>

          <Stack direction="column" width="full">
            <Input
              variant="outline"
              placeholder="Name of your project"
              value={text}
              onChange={(e) => setText(e.target.value)}
            />

            <Spacer />
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Stack direction="row" width="full" justifyContent="end">
            <Button variant="outline" onClick={handleClose}>
              Cancel
            </Button>
            <Tooltip label={text.length === 0 ? "Please provide a project name" : ""} shouldWrapChildren>
              <Button variant="solid" onClick={handleSubmit} isDisabled={text.length === 0}>
                Create new Project
              </Button>
            </Tooltip>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
