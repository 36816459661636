import { Stack, Text } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from '../../lib/hooks';
import { motion } from 'framer-motion';
import {
  incrementRefreshCounter,
  removeDoc,
  setToolOpenStatus,
} from '../../lib/state/slices/ProjectStateSlice';

import theme from '../../theme';
import { ChevronRight } from 'react-feather';
import { useState } from 'react';
import DocumentCard from '../DocumentCard';
import FolderSelector from '../FolderSelector';

export default function SavedDocumentsList() {
  const folders = useAppSelector((state) => state.viewReducer.folders);
  const dispatch = useAppDispatch();
  const [openFolder, setOpenFolder] = useState<string | null>('default');


  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'clip',
        padding: '10px',
      }}
    >
      <FolderSelector onFolderOpen={setOpenFolder} />
      <Stack
        direction={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        padding={'10px'}
        overflowY={'auto'}
        overflowX={'hidden'}
        display={'grid'}
        marginTop={'10px'}
        style={{
          scrollbarWidth: 'thin',
        }}
      >
        {openFolder
          ? folders
            .find((folder) => folder.id === openFolder)
            ?.documents.map((doc) => (
              <DocumentCard
                key={doc}
                id={doc}
                nodeId=""
                onClose={() => dispatch(removeDoc({ folderId: openFolder, nodeId: doc }))}
              />
            ))
          : null}
      </Stack>
    </div>
  );
}
