import { Article, CaseDetailType, Chambertype, Country, Importancetype, Node } from '../api/APITypes';
import SerializedGraph from 'graphology-types';

type ProjectState = {
  id: string;
  name: string;
  folders: Folder[]; //IDs of the documents
  graphViews: { [key: string]: GraphView }; //Each graphview becomes a new tab
  refreshCounter: number;
  searchQuery: string;
  searchMode: SearchMode;
  exactSearch: Node[];
  contextualSearch: Node[];
  isAutosaveActive: boolean;
  toolOpenStatus: {
    savedCases: boolean;
    advancedFilters: boolean;
  };
  conversations: Conversation[];
  openConversationId: string;
};

export type Tooltype = 'quickfilter' | 'filter' | 'timeline' | 'saved_documents' | 'search';

export type GraphView = {
  id: string;
  name: string;
  centerNode: string; // hudoc:001-12345
  serializedGraph: SerializedGraph | null;
  floatingDocuments: {
    nodeId: string;
    id: string;
  }[]; // These are the floating documents in the graph
  filter: Filter;
};

export type Filter = {
  timeFilter: {
    upper: number;
    lower: number;
  };
  caseDetail: CaseDetailType[];
  importance: Importancetype[]; // Importance levels are numbers between 0-4. This array contains all active importance levels
  decisionLevel: Chambertype[]; // CHAMBER, GRANDCHAMBER or COMMITTEE
  countrys: Country[];
  articles: Article[];
};

type Folder = {
  id: string;
  name: string;
  documents: string[];
};

type Conversation = {
  id: string;
  name: string;
  messages: Message[];
  datetime: number; // unix timestamp
};

interface Message {
  conversationId: string;
  datetime: number; // unix timestamp
  issuer: "user" | "system";
  content: string;
}
export enum SearchMode {
  "CONTEXT",
  "EXACT",
}

export default ProjectState;

export type { Folder, Message, Conversation };
