import { IconButton, Stack, Textarea, Tag, Text, Flex, Icon, Img, Spinner, useDisclosure, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Popover, PopoverContent, PopoverTrigger, Portal, useToast, Input, Tooltip } from '@chakra-ui/react';
import { Book, ChevronDown, Delete, Edit2, List, MoreHorizontal, MoreVertical, PlusCircle, Send, Trash2, User, XCircle } from 'react-feather';
import { useAppDispatch, useAppSelector } from '../../lib/hooks';
import { useGetAIResponseMutation } from '../../lib/api/GraphAPI';
import { useEffect, useRef, useState } from 'react';
import { addConversation, addMessage, clearConversation, removeConversation, renameConversation, setOpenConversationId } from '../../lib/state/slices/ProjectStateSlice';
import { useLocation } from 'react-router-dom';
import { Node } from '../../lib/api/APITypes';
import { Conversation, Message, SearchMode } from '../../lib/state/ViewTypes';
import theme from '../../theme';
import { v4 as uuidv4 } from 'uuid';
import ContextMenu, { ContextMenuButton } from '../ContextMenu';
import { ChatIcon } from '@chakra-ui/icons';
import Markdown from 'react-markdown';
import { on } from 'events';

const ChatMessage = (props: { message: Message }) => {
    if (props.message.issuer === 'user') {
        return <Flex direction={'column'}>
            <Flex direction={'row'} alignItems={'center'}><Icon as={User} background={theme.colors.blue[700]} color={'white'} borderRadius="50%" padding={"2px"} />
                <Text fontWeight={600} color={theme.colors.blue[700]} marginStart={2}>You</Text></Flex>
            <Text
                background={theme.colors.blue[200]}
                paddingStart={2}
                paddingEnd={2}
                paddingTop={1}
                paddingBottom={1}
                fontWeight={400}
                fontSize={'sm'}
                color={theme.colors.blue[800]}
                borderRadius="10px">
                {props.message.content}
            </Text>
        </Flex>
    } else {
        return <Flex direction={'column'}>
            <Flex direction={'row'} alignItems={'center'}><Img src={"/logo192.png"} width={"16px"} background={theme.colors.blue[700]} color={'white'} borderRadius="50%" />
                <Text fontWeight={600} color={theme.colors.purple[700]} marginStart={2}>Meshy</Text></Flex>
            <Text
                fontWeight={400}
                fontSize={'sm'}
                color={theme.colors.blue[800]}
                borderRadius="10px">
                <Markdown>{props.message.content}</Markdown>
            </Text>
        </Flex>
    }
}

const RAGChat = () => {
    const toast = useToast();

    const [typedMessage, setTypedMessage] = useState('');
    const [clearFunction, setClearFunction] = useState(() => () => { });
    const [newName, setNewName] = useState('');
    const dispatch = useAppDispatch();
    const [responseQuery, { isLoading }] = useGetAIResponseMutation();
    const openConversationId = useAppSelector((state) => state.viewReducer.openConversationId);
    const conversations = useAppSelector((state) => state.viewReducer.conversations);
    const { isOpen: isClearChatOpen, onOpen: onClearChatOpen, onClose: onClearChatClose } = useDisclosure()
    const { isOpen: isChatMenuOpen, onOpen: onChatMenuOpen, onClose: onChatMenuClose } = useDisclosure()
    const { isOpen: isDeleteChatOpen, onOpen: onDeleteChatOpen, onClose: onDeleteChatClose } = useDisclosure()
    const { isOpen: isRenameChatOpen, onOpen: onRenameChatOpen, onClose: onRenameChatClose } = useDisclosure()
    const { isOpen: isChatContextMenuOpen, onOpen: onChatContextMenuOpen, onClose: onChatContextMenuClose } = useDisclosure()


    const initialFocusRef = useRef(null);

    useEffect(() => {
        if (conversations.find((conversation) => conversation.id === openConversationId) === undefined) {
            console.log("didnt fint convo ID, resetting it")
            dispatch(setOpenConversationId(conversations[0].id))
        }
    }, [openConversationId])

    let msgs: Message[] = conversations.find((conversation) => conversation.id === openConversationId) ? conversations.find((conversation) => conversation.id === openConversationId)!.messages : [];

    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    const getResponse = (msg: string) => {
        responseQuery({
            conversationId: openConversationId,
            history: [...msgs, { conversationId: openConversationId, content: msg, issuer: 'user', datetime: new Date().getTime() }],
            contextIds: currentContext.map((node) => node.properties.id)
        }).then((result) => {
            dispatch(addMessage(result.data!))
            console.log(result.data)
        })
    }

    const submitMessage = () => {
        setTypedMessage('');
        dispatch(addMessage({ conversationId: openConversationId, content: typedMessage, issuer: 'user', datetime: new Date().getTime() }))
        getResponse(typedMessage);
        clearFunction();
    }

    useEffect(() => {
        scrollToBottom();
    }, [msgs]);
    const location = useLocation()
    const [currentContext, setCurrentContext] = useState<Node[]>([]);
    const exactSearch = useAppSelector((state) => state.viewReducer.exactSearch);
    const contextualSearch = useAppSelector((state) => state.viewReducer.contextualSearch);
    const searchMode = useAppSelector((state) => state.viewReducer.searchMode);

    useEffect(() => {
        if (location.pathname.includes('research')) {
            if (searchMode === SearchMode.CONTEXT) {
                setCurrentContext(contextualSearch)
            } else {
                setCurrentContext(exactSearch)
            }
        } else {
            setCurrentContext([])
        }
    }, [location.pathname, exactSearch, contextualSearch, searchMode]);


    return (
        <Stack direction={'column'} justifyContent={'space-between'} height={'100%'}>

            <Flex direction={'row'} alignItems={'center'} padding={2} justifyContent={'space-between'} background={theme.colors.gray[100]}>

                <Popover initialFocusRef={initialFocusRef}
                    isOpen={isChatContextMenuOpen} onOpen={onChatContextMenuOpen} onClose={onChatContextMenuClose}>
                    <PopoverTrigger>
                        <div style={{ display: 'flex', alignItems: 'center', maxWidth: '50%', flex: 1, cursor: 'pointer' }}>
                            <Text fontWeight={600} color={theme.colors.purple[700]} noOfLines={1} >{conversations.find((conversation: Conversation) => conversation.id === openConversationId)?.name}</Text>
                            <Icon as={ChevronDown} color={theme.colors.gray[600]} marginStart={2} marginEnd={2} />
                        </div>
                    </PopoverTrigger>
                    <Portal>
                        <PopoverContent width="max-content">
                            <ContextMenu onClose={onChatContextMenuClose}>
                                <ContextMenuButton icon={Trash2} text={'Delete'} onClick={() => { onDeleteChatOpen(); onChatContextMenuClose() }} />
                                <ContextMenuButton icon={Edit2} text={'Rename'} onClick={() => { onRenameChatOpen(); onChatContextMenuClose() }} />
                                <ContextMenuButton icon={XCircle} text={'Clear'} onClick={() => { onClearChatOpen(); onChatContextMenuClose() }} />
                            </ContextMenu>
                        </PopoverContent>
                    </Portal>
                </Popover>
                <Flex alignItems={'center'} marginStart={2} marginEnd={2} gap={2}>
                    <Popover initialFocusRef={initialFocusRef}
                        isOpen={isChatMenuOpen} onOpen={onChatMenuOpen} onClose={onChatMenuClose}>
                        <PopoverTrigger>
                            <Flex alignItems={'center'} gap={1} cursor={'pointer'}><Icon as={List} color={theme.colors.gray[600]} /><Text fontWeight={400} color={theme.colors.gray[600]} fontSize={'xs'}>Chat List</Text></Flex>
                        </PopoverTrigger>
                        <Portal>
                            <PopoverContent width="max-content">
                                <ContextMenu onClose={onChatMenuClose}>
                                    {conversations.map((conversation) => <ContextMenuButton key={conversation.id} icon={ChatIcon} text={conversation.name} onClick={() => {
                                        dispatch(setOpenConversationId(conversation.id))
                                        onChatMenuClose()
                                    }} />)}
                                </ContextMenu>
                            </PopoverContent>
                        </Portal>
                    </Popover>

                    <Flex alignItems={'center'} gap={1} cursor={'pointer'} onClick={async () => {
                        let newConvoId = uuidv4();
                        await dispatch(addConversation({ id: newConvoId, name: 'New Chat', datetime: new Date().getTime(), messages: [] }))
                        await dispatch(setOpenConversationId(newConvoId))
                    }}><Icon as={PlusCircle} color={theme.colors.gray[600]} /><Text fontWeight={400} color={theme.colors.gray[600]} fontSize={'xs'}>New Chat</Text></Flex>




                </Flex>
            </Flex>

            <div style={{ height: '100%', overflow: 'auto', scrollbarWidth: 'thin' }}>
                <Stack direction={'column'} spacing={5} padding={2}>
                    {msgs.map((message) => <ChatMessage key={message.datetime} message={message} />)}
                </Stack>
                <div ref={messagesEndRef} /> {/* Invisible div to scroll to */}
            </div>
            {isLoading &&
                <Flex direction={'row'} alignItems={'center'}><Spinner size="sm" /> <Text fontWeight={400}
                    fontSize={'sm'} marginStart={1}>Meshy is thinking</Text></Flex>
            }
            <Stack spacing={1} background={'gray.100'} padding={2} >
                <Text fontSize={'xs'} fontWeight={600} color={'gray.600'}>Current Context: </Text>
                <Flex direction={'row'} border={'1px'} borderColor={'purple.200'} borderRadius={'15px'} width={'-webkit-fit-content'} alignItems={'center'} padding={"2px"} paddingStart={"5px"} paddingEnd={"5px"}>
                    <Icon as={Book} color={'purple.700'} flexShrink={0} />
                    <Tooltip label={currentContext.length === 0 ? "Current context is empty. The assistent is not available on Graphs and Folders yet" : "Ask questions about these cases: " + currentContext.map((node) => (node.properties.label)).join(', ')}>
                        <Text
                            fontSize={'xs'}
                            fontWeight={700}
                            color={'blue.700'}
                            flexGrow={1}
                            flexBasis={'0px'}
                            noOfLines={1}
                        >
                            {currentContext.map((node) => (node.properties.label?.split('CASE OF')[1])).join(', ')}
                        </Text>
                    </Tooltip>
                    {currentContext.length > 1 &&
                        <Text flexGrow={0} noOfLines={1} fontSize={'xs'} fontWeight={700} color={'purple.700'} flexShrink={0}>
                            + {currentContext.length - 1} more
                        </Text>}
                </Flex>
                <Stack direction="row" alignItems="center">

                    <Textarea
                        disabled={isLoading}
                        resize="none"
                        placeholder={currentContext.length === 1 ? "Ask anything about the case you have selected..." : "Ask anything about the cases you have selected..."}
                        onKeyDown={(e) => e.key === 'Enter' && submitMessage()}
                        onChange={(e) => setTypedMessage(e.target.value)}
                        value={typedMessage}
                        background={'white'}
                        style={{ scrollbarWidth: 'thin' }}
                        flex="1"
                    />
                    <IconButton
                        disabled={isLoading}
                        size='xs'
                        background={'blue.700'}
                        color={'white'}
                        aria-label='Send'
                        icon={<Send width={'16px'} height={'16px'} />}
                        onClick={submitMessage}
                    />
                </Stack>
            </Stack>
            <Modal isOpen={isClearChatOpen} onClose={onClearChatClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Clear Chat</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text>Are you sure you want to clear the chat?</Text>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="red" onClick={() => {
                            console.log(openConversationId)
                            dispatch(clearConversation(openConversationId));
                            onClearChatClose();
                        }}>Clear</Button>
                        <Button variant="ghost" onClick={onClearChatClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Modal isOpen={isDeleteChatOpen} onClose={onDeleteChatClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Delete Confirmation</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text>Are you sure you want to delete this item?</Text>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="red" onClick={() => {
                            if (conversations.length > 1) {
                                dispatch(removeConversation(openConversationId));
                                dispatch(setOpenConversationId(conversations[0].id));
                            } else {
                                toast({
                                    title: "Cannot Delete",
                                    description: "You cannot delete the last conversation.",
                                    status: "warning",
                                    duration: 5000,
                                    isClosable: true,
                                });
                            }
                            onDeleteChatClose();
                        }}>Delete</Button>
                        <Button variant="ghost" onClick={onDeleteChatClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Modal isOpen={isRenameChatOpen} onClose={onRenameChatClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Rename Conversation</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Stack>
                            <Text>Enter new name for "{conversations.find((conv) => conv.id === openConversationId)?.name}"</Text>
                            <Input value={newName} onChange={(e) => setNewName(e.target.value)} />
                        </Stack>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => {
                            if (newName === "") {
                                toast({
                                    title: "Invalid Name",
                                    description: "Conversation name cannot be empty.",
                                    status: "warning",
                                    duration: 5000,
                                    isClosable: true,
                                })
                            } else {
                                dispatch(renameConversation({ id: openConversationId, name: newName }));
                                onRenameChatClose();
                            }

                        }}>Rename</Button>
                        <Button variant="ghost" onClick={onRenameChatClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

        </Stack>
    );
};

export default RAGChat;
