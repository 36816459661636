import { Button, Text } from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "../../lib/hooks";
import { setToolOpenStatus } from "../../lib/state/slices/ProjectStateSlice";

export default function Header(props: { graphName: string; isListView: boolean; toggleView: () => void }) {
  const toolOpenStatus = useAppSelector((state) => state.viewReducer.toolOpenStatus);
  const dispatch = useAppDispatch();
  return (
    <div
      style={{
        width: '100%',
        padding: '20px',
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <div>
        <Text fontWeight={800} fontSize={'x-large'}>
          {props.graphName}
        </Text>
      </div>
      <div>
        <Button marginLeft="12px" variant="outline" colorScheme="purple" onClick={props.toggleView}>
          {/* todo: fix the placement of buttons, rn: hardcoded not good */}
          {props.isListView ? 'View as graph' : 'View as list'}
        </Button>
        <Button
          marginLeft={'10px'}
          type="button"
          variant="outline"
          colorScheme="purple"
          onClick={() =>
            dispatch(
              setToolOpenStatus({
                ...toolOpenStatus,
                advancedFilters: !toolOpenStatus.advancedFilters,
              }),
            )
          }
        >
          Filter
        </Button>
      </div>
    </div>
  );
};