import { useLocation } from 'react-router-dom';
import { useAppSelector } from '../lib/hooks';
import { GraphLayout } from './GraphPage/Index';
import { Box, Stack, Text, theme } from '@chakra-ui/react';

export default function GraphPage() {
  const graphView = useAppSelector((state) => state.viewReducer.graphViews);
  const location = useLocation();
  if (Object.values(graphView).length === 0)
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        No Graphs available
      </div>
    );
    
  if (location.pathname === '/graph') {
    return (
      <Stack
        direction="column"
        width="100%"
        padding={4}
        rowGap={4}
      >
        <Box
          width="100%"
          padding={4}
          borderRadius="0.375rem"
          bgColor={theme.colors.gray[100]}
        >
          <Text fontWeight={700} fontSize={'xx-large'}>
            Graph Overview
          </Text>
        </Box>
        
        <Box
          width="100%"
          height="100%"
          padding={4}
          borderRadius="0.375rem"
          bgColor={theme.colors.gray[100]}
        >          
          <Text>
            Page coming soon
          </Text>
        </Box>
      </Stack>
    )
  };
  
  return (
    <GraphLayout
      key={parseInt(location.pathname.split('/graph/')[1])}
      graphViewId={location.pathname.split('/graph/')[1]}
    />
);
}
