import { theme } from "@chakra-ui/theme";
import { Message } from "../state/ViewTypes";

interface Graph {
  edges: Array<Relationship>;
  nodes: Array<Node>;
}

interface Node {
  elementId: string;
  labels: string[];
  properties: {
    id: string;
    label?: string;
    appno: string;
    country: Country[];
    importance: Importancetype;
    judgementdate?: string;
    description?: string;
    chambertype: Chambertype;
    separateopinion: boolean;
    keywords: string[];
    violation: string;
    nonviolation: string;
    url: string;
  };
}

interface Relationship {
  elementId: string;
  startNodeElementId: string;
  endNodeElementId: string;
  type: string;
  properties: {
    weight: number;
  };
}
export const ImportanceNames = ['EurLex', 'Key Case', 'Level 1', 'Level 2', 'Level 3'];
export const CaseDetailNames = {
  "VIOLATION": 'Violation', "NONVIOLATION": 'Non-violation', "BOTH": 'Both', "MISCELLANEOUS": 'Miscellaneous'}
export const CaseDetailParams = ['nonviolation', 'violation', 'TODO:REPLACE', 'TODO:REPLACE'];

export const CaseDetailColors = {
 "NONVIOLATION": theme.colors.green[400],
  "VIOLATION": theme.colors.red[500],
  "BOTH": theme.colors.orange[400],
  "MISCELLANEOUS": theme.colors.cyan[400],
};

type Importancetype = 0 | 1 | 2 | 3 | 4;
type CaseDetailType = "VIOLATION" | "NONVIOLATION" | "BOTH" | "MISCELLANEOUS";

let importancetypes: Importancetype[] = [1, 2, 3, 4];
let caseDetailTypes: CaseDetailType[] = ["VIOLATION", "NONVIOLATION", "BOTH", "MISCELLANEOUS"];

type Article = "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9" | "10" | "11" | "12" | "13" | "14" | "15" | "16" | "17" | "18" | "p1-1" | "p1-2" | "p1-3" | "p4-2" | "p6-1" | "p7-2" | "p7-3" | "p12-1";
let articleTypes : Article[] = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "p1-1", "p1-2", "p1-3", "p4-2" , "p6-1" ,  "p7-2", "p7-3", "p12-1" ];

type Chambertype = 'COMMITTEE' | 'CHAMBER' | 'GRANDCHAMBER' | 'ICJ';
let chambertypes: Chambertype[] = ['COMMITTEE', 'CHAMBER', 'GRANDCHAMBER', 'ICJ'];

type Country = 'SRB' | 'BGR' | 'RUS' | 'POL' | 'ARM' | 'LTU' | 'GBR' | 'ROU' | 'SWE' | 'SVN' | 'ESP' | 'AZE' | 'AUT' | 'CHE' | 'SVK' | 'UKR' | 'HRV' | 'DEU' | 'GRC' | 'HUN' | 'BIH' | 'TUR' | 'MLT' | 'NOR' | 'ITA' | 'CYP' | 'FRA' | 'BEL' | 'ALB' | 'MDA' | 'FIN' | 'SMR' | 'CZE' | 'MNE' | 'MKD' | 'EST' | 'NLD' | 'LVA' | 'DNK' | 'PRT' | 'GEO' | 'LIE' | 'IRL' | 'ISL' | 'LUX' | 'AND' | 'ALB';

let countrytypes: Country[] = ['SRB', 'BGR', 'RUS', 'POL', 'ARM', 'LTU', 'GBR', 'ROU', 'SWE', 'SVN', 'ESP', 'AZE', 'AUT', 'CHE', 'SVK', 'UKR', 'HRV', 'DEU', 'GRC', 'HUN', 'BIH', 'TUR', 'MLT', 'NOR', 'ITA', 'CYP', 'FRA', 'BEL', 'ALB', 'MDA', 'FIN', 'SMR', 'CZE', 'MNE', 'MKD', 'EST', 'NLD', 'LVA', 'DNK', 'PRT', 'GEO', 'LIE', 'IRL', 'ISL', 'LUX', 'AND'];

let mappedArticles = 
  {
    "1": "Article 1: Obligation to respect human rights",
    "2": "Article 2: Right to life",
    "3": "Article 3: Prohibition of torture",
    "4": "Article 4: Prohibition of slavery and forced labour",
    "5": "Article 5: Right to liberty and security",
    "6": "Article 6: Right to a fair trial",
    "7": "Article 7: No punishment without law",
    "8": "Article 8: Right to respect for private and family life",
    "9": "Article 9: Freedom of thought, conscience and religion",
    "10": "Article 10: Freedom of expression",
    "11": "Article 11: Freedom of assembly and association",
    "12": "Article 12: Right to marry",
    "13": "Article 13: Right to an effective remedy",
    "14": "Article 14: Prohibition of discrimination",
    "15": "Article 15: Derogation in time of emergency",
    "16": "Article 16: Restrictions on political activity of aliens",
    "17": "Article 17: Prohibition of abuse of rights",
    "18": "Article 18: Limitation on use of restrictions of rights",
    'p1-1': 'Article 1 of Protocol No. 1: Protection of property',
    'p1-2':'Article 2 of Protocol No. 1: Right to education',
    'p1-3':'Article 3 of Protocol No. 1: Right to free elections',
    'p4-2':'Article 2 of Protocol No. 4: Freedom of movement',
    'p6-1': 'Article 1 of Protocol No. 6: Abolition of the death penalty',
   'p7-2':'Article 2 of Protocol No. 7: Right of appeal in criminal matters',
    'p7-3': "Article 3 of Protocol No. 7: Compensation for wrongful conviction",
    'p12-1':'Article 1 of Protocol No. 12: General prohibition of discrimination',
  }


let mappedCountries = {
  "SRB": "Serbia",
  "BGR": "Bulgaria",
  "RUS": "Russia",
  "POL": "Poland",
  "ARM": "Armenia",
  "LTU": "Lithuania",
  "GBR": "United Kingdom",
  "ROU": "Romania",
  "SWE": "Sweden",
  "SVN": "Slovenia",
  "ESP": "Spain",
  "AZE": "Azerbaijan",
  "AUT": "Austria",
  "CHE": "Switzerland",
  "SVK": "Slovakia",
  "UKR": "Ukraine",
  "HRV": "Croatia",
  "DEU": "Germany",
  "GRC": "Greece",
  "HUN": "Hungary",
  "BIH": "Bosnia and Herzegovina",
  "TUR": "Turkey",
  "MLT": "Malta",
  "NOR": "Norway",
  "ITA": "Italy",
  "CYP": "Cyprus",
  "FRA": "France",
  "BEL": "Belgium",
  "ALB": "Albania",
  "MDA": "Moldova",
  "FIN": "Finland",
  "SMR": "San Marino",
  "CZE": "Czech Republic",
  "MNE": "Montenegro",
  "MKD": "North Macedonia",
  "EST": "Estonia",
  "NLD": "Netherlands",
  "LVA": "Latvia",
  "DNK": "Denmark",
  "PRT": "Portugal",
  "GEO": "Georgia",
  "LIE": "Liechtenstein",
  "IRL": "Ireland",
  "ISL": "Iceland",
  "LUX": "Luxembourg",
  "AND": "Andorra"
}

interface ResponseQuery {
  conversationId: string;
  history: Message[];
  contextIds: string[];
}

interface File {
  docname: string;
  id: string;
  summary: string;
  body: string;
}

export { chambertypes, importancetypes, caseDetailTypes, countrytypes, mappedCountries, articleTypes, mappedArticles };
export type { Graph, Relationship, Node, File, Chambertype, Importancetype, CaseDetailType, Country, Article, ResponseQuery };
