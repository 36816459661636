import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Graph, Node, Relationship, File, ResponseQuery } from './APITypes';
import View, { Message } from '../state/ViewTypes';
import ProjectState from '../state/ViewTypes';

// API endpoint for fetching data
export const graphApi = createApi({
  reducerPath: 'graphApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, api) => {
      let ai: any = api.getState();
      headers.set('Authorization', 'Bearer ' + ai.authReducer.accesstoken);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    // <Type of data the call will return, Type of parameter being passed to the query function>
    getGraph: builder.query<Graph, void>({
      query: () => 'graph',
    }),
    getNodes: builder.query<Array<Node>, void>({
      query: () => 'nodes',
    }),
    getNode: builder.query<Node, string>({
      query: (id) => `node?id=${id}`,
    }),
    getEdges: builder.query<Array<Relationship>, void>({
      query: () => 'edges',
    }),
    getNeighbours: builder.query<Graph, { id: string; level: number }>({
      query: ({ id, level }) => `neighbours?id=${id}&level=${level}`,
    }),
    getRecommendedNeighbours: builder.query<
      { nodes: Array<Node>; distances: Array<number> },
      string
    >({
      query: (id) => `ai/neighbours?id=${id}`,
    }),
    getConclusion: builder.query<string, string>({
      query: (id) => `conclusion?id=${id}`,
    }),
    getSummary: builder.query<string, string>({
      query: (id) => `summary?id=${id}`,
    }),
    search: builder.query<Array<Node>, string>({
      query: (q) => `search?q=${q}`,
    }),
    AIsearch: builder.query<{ nodes: Array<Node>; distances: Array<number> }, string>({
      query: (q) => `ai/search?q=${q}`,
    }),

    getProjectState: builder.query<Array<{ id: string; json: ProjectState }>, string>({
      query: (q) => `project?psId=${q}`,
    }),
    getAIResponse: builder.mutation<Message, ResponseQuery>({
      query: (body) => ({
        url: `ai/respond`,
        method: 'POST',
        body
      })
    }),
    saveProjectState: builder.mutation<void, ProjectState>({
      query: (body) => ({
        url: `project`,
        method: 'POST',
        body,
      }),
    }),
    removeProjectState: builder.mutation<void, string>({
      query: (q) => ({
        url: `project?psId=${q}`,
        method: 'DELETE',
      }),
    }),
    getAllProjectStates: builder.query<Array<{ json: ProjectState, last_modified: string, }>, void>({
      query: () => 'projects',
    }),
    getActiveProjectState: builder.query<Array<{ json: ProjectState }>, void>({
      query: () => 'project/active',
    }),
    setActiveProjectState: builder.mutation<void, string>({
      query: (body) => ({
        url: `project/active`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useGetGraphQuery,
  useGetEdgesQuery,
  useGetNodesQuery,
  useGetNodeQuery,
  useGetConclusionQuery,
  useGetSummaryQuery,
  useGetNeighboursQuery,
  useSearchQuery,
  useAIsearchQuery,
  useLazyGetNodeQuery,
  useGetRecommendedNeighboursQuery,
  useGetProjectStateQuery,
  useSaveProjectStateMutation,
  useRemoveProjectStateMutation,
  useLazyGetAllProjectStatesQuery,
  useLazyGetActiveProjectStateQuery,
  useSetActiveProjectStateMutation,
  useLazyGetNeighboursQuery,
  useLazyGetRecommendedNeighboursQuery,
  useGetAIResponseMutation,
} = graphApi;
