import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

import ProjectState, { Conversation, Filter, Folder, GraphView, Message, SearchMode } from '../ViewTypes';
import { Article, CaseDetailType, Chambertype, Country, Importancetype, Node } from '../../api/APITypes';
import SerializedGraph from 'graphology-types';
import { cloneDeep } from 'lodash';

export const initialProjectState: ProjectState = {
  id: uuidv4(),
  name: 'Loading...',
  folders: [{ id: 'default', name: 'Default', documents: [] }],
  graphViews: {},
  searchQuery: '',
  searchMode: SearchMode.CONTEXT,
  exactSearch: [],
  contextualSearch: [],
  refreshCounter: 0,
  isAutosaveActive: false,
  toolOpenStatus: {
    savedCases: false,
    advancedFilters: true,
  },
  conversations: [
    {
      id: uuidv4(),
      name: 'New Chat',
      messages: [
        {
          issuer: 'system',
          content: 'Hey, I am Meshy, your friendly virtual assistant. You can ask questions about the cases from the search results on the Homepage.',
          conversationId: '',
          datetime: new Date().getTime(),
        },
      ],
      datetime: new Date().getTime(),
    },
  ],
  openConversationId: "",
};

export const newInitialProjectState = (override: Partial<ProjectState>): ProjectState => {
  
  const newProjectState = cloneDeep(initialProjectState);
  
  newProjectState.id = uuidv4();
  
  return { ...newProjectState, ...override };
}

export const projectStateSlice = createSlice({
  name: 'projectState',
  initialState: initialProjectState,
  reducers: {
    setProjectState: (state, action: { payload: ProjectState }) => {
      for (const key of Object.keys(action.payload)) {
        (state as any)[key] = (action.payload as any)[key];
      }
    },
    addMessage: (state, action: { payload: Message }) => {
      let conversation = state.conversations.find(
        (conversation) => conversation.id === action.payload.conversationId,
      )
      if (!conversation) return;
      conversation.messages.push(action.payload);
    },
    clearConversation: (state, action: { payload: string }) => {
      let conversation = state.conversations.find(
        (conversation) => conversation.id === action.payload,
      )
      if (!conversation) return;
      conversation.messages = [];
    },
    renameConversation: (state, action: { payload: { id: string; name: string } }) => {
      let conversation = state.conversations.find(
        (conversation) => conversation.id === action.payload.id,
      )
      if (!conversation) return;
      conversation.name = action.payload.name
    },
    addConversation: (state, action: { payload: Conversation }) => {
      state.conversations.push(action.payload);
    },
    removeConversation: (state, action: { payload: string }) => {
      const index = state.conversations.findIndex((conversation) => conversation.id === action.payload);
      if (index > -1 && state.conversations.length > 1) state.conversations.splice(index, 1);
    },
    setOpenConversationId: (state, action: { payload: string }) => {
      if (action.payload === state.openConversationId) return;
      if (!state.conversations.find((conversation) => conversation.id === action.payload)) return;
      state.openConversationId = action.payload
    },
    addFolder: (state, action: { payload: string }) => {
      state.folders.push({
        id: uuidv4(),
        name: action.payload,
        documents: [],
      });
    },
    updateFolder: (state, action: { payload: Folder }) => {
      let folder_index = state.folders.findIndex((folder) => folder.id === action.payload.id);
      state.folders[folder_index] = action.payload;
    },
    removeFolder: (state, action: { payload: string }) => {
      const index = state.folders.findIndex((folder) => folder.id === action.payload);
      if (index > -1 && state.folders.length > 1) state.folders.splice(index, 1);
    },
    saveDoc: (state, action: { payload: { folderId: string; nodeId: string } }) => {
      let folder = state.folders.find((folder) => folder.id === action.payload.folderId);
      if (!folder) return;
      for (const docid of folder.documents) {
        if (docid === action.payload.nodeId) {
          return;
        }
      }
      folder.documents.push(action.payload.nodeId);
    },
    removeDoc: (state, action: { payload: { folderId: string; nodeId: string } }) => {
      let folderIndex = state.folders.findIndex((folder) => folder.id === action.payload.folderId);
      if (folderIndex < 0) return;
      const index = state.folders[folderIndex].documents.indexOf(action.payload.nodeId);
      if (index > -1) state.folders[folderIndex].documents.splice(index, 1); // 2nd parameter means remove one item only
    },
    addFloatingDoc: (
      state,
      action: {
        payload: {
          graphViewId: string;
          nodeId: string;
          id: string;
        };
      },
    ) => {
      let graphView = state.graphViews[action.payload.graphViewId];
      let floatingDocumentIndex = graphView.floatingDocuments.findIndex(
        (doc) => doc.nodeId === action.payload.nodeId,
      );
      if (floatingDocumentIndex === -1)
        graphView.floatingDocuments.push({
          nodeId: action.payload.nodeId,
          id: action.payload.id,
        });
    },

    removeFloatingDoc: (
      state,
      action: { payload: { graphViewId: string; floatingDocId: string } },
    ) => {
      let graphView = state.graphViews[action.payload.graphViewId];

      let floatingDocumentIndex = graphView.floatingDocuments.findIndex(
        (doc) => doc.id === action.payload.floatingDocId,
      );
      if (floatingDocumentIndex > -1) graphView.floatingDocuments.splice(floatingDocumentIndex, 1);
    },
    setCenterNode: (state, action: { payload: { graphViewId: string; centerNode: string } }) => {
      let graphView = state.graphViews[action.payload.graphViewId];
      graphView.centerNode = action.payload.centerNode;
    },
    setFilter: (state, action: { payload: { graphViewId: string; filter: Filter } }) => {
      let graphView = state.graphViews[action.payload.graphViewId];
      graphView.filter = action.payload.filter;
    },
    setTimeFilter: (
      state,
      action: {
        payload: {
          graphViewId: string;
          timeFilter: {
            upper: number;
            lower: number;
          };
        };
      },
    ) => {
      let graphView = state.graphViews[action.payload.graphViewId];
      graphView.filter.timeFilter = action.payload.timeFilter;
    },
    addImportanceFilter: (
      state,
      action: {
        payload: { importance: Importancetype; graphViewId: string };
      },
    ) => {
      let graphView = state.graphViews[action.payload.graphViewId];
      if (!graphView.filter.importance.includes(action.payload.importance)) {
        graphView.filter.importance.push(action.payload.importance);
      }
    },
    removeImportanceFilter: (
      state,
      action: {
        payload: { importance: Importancetype; graphViewId: string };
      },
    ) => {
      let graphView = state.graphViews[action.payload.graphViewId];
      let importance_index = graphView.filter.importance.findIndex(
        (value) => value === action.payload.importance,
      );
      if (importance_index > -1) {
        graphView.filter.importance.splice(importance_index, 1);
      }
    },
    addCaseDetailFilter: (
      state,
      action: {
        payload: { caseDetailLevel: CaseDetailType; graphViewId: string };
      },
    ) => {
      let graphView = state.graphViews[action.payload.graphViewId];
      if (!graphView.filter.caseDetail.includes(action.payload.caseDetailLevel)) {
        graphView.filter.caseDetail.push(action.payload.caseDetailLevel);
      }
    },
    removeCaseDetailFilter: (
      state,
      action: {
        payload: { caseDetailLevel: CaseDetailType; graphViewId: string };
      },
    ) => {
      let graphView = state.graphViews[action.payload.graphViewId];
      let caseDetail_index = graphView.filter.caseDetail.findIndex(
        (value) => value === action.payload.caseDetailLevel,
      );
      if (caseDetail_index > -1) {
        graphView.filter.caseDetail.splice(caseDetail_index, 1);
      }
    },
    addDecisionLevelFilter: (
      state,
      action: {
        payload: { decisionLevel: Chambertype; graphViewId: string };
      },
    ) => {
      let graphView = state.graphViews[action.payload.graphViewId];
      if (!graphView.filter.decisionLevel.includes(action.payload.decisionLevel)) {
        graphView.filter.decisionLevel.push(action.payload.decisionLevel);
      }
    },
    removeDecisionLevelFilter: (
      state,
      action: {
        payload: { decisionLevel: Chambertype; graphViewId: string };
      },
    ) => {
      let graphView = state.graphViews[action.payload.graphViewId];
      let decision_level_index = graphView.filter.decisionLevel.findIndex(
        (value) => value === action.payload.decisionLevel,
      );
      if (decision_level_index > -1) {
        graphView.filter.decisionLevel.splice(decision_level_index, 1);
      }
    },
    addArticleFilter: (state, action: { payload: { article: Article; graphViewId: string } }) => {
      let graphView = state.graphViews[action.payload.graphViewId];
      if (!graphView.filter.articles.includes(action.payload.article)) {
        graphView.filter.articles.push(action.payload.article);
      }
    },
    removeArticleFilter: (
      state,
      action: { payload: { article: Article; graphViewId: string } },
    ) => {
      let graphView = state.graphViews[action.payload.graphViewId];
      let article_index = graphView.filter.articles.findIndex(
        (value) => value === action.payload.article,
      );
      if (article_index > -1) {
        graphView.filter.articles.splice(article_index, 1);
      }
    },
    addCountryFilter: (state, action: { payload: { country: Country; graphViewId: string } }) => {
      let graphView = state.graphViews[action.payload.graphViewId];
      if (!graphView.filter.countrys.includes(action.payload.country)) {
        graphView.filter.countrys.push(action.payload.country);
      }
    },
    removeCountryFilter: (
      state,
      action: { payload: { country: Country; graphViewId: string } },
    ) => {
      let graphView = state.graphViews[action.payload.graphViewId];
      let country_index = graphView.filter.countrys.findIndex(
        (value) => value === action.payload.country,
      );
      if (country_index > -1) {
        graphView.filter.countrys.splice(country_index, 1);
      }
    },
    setCountryFilter: (
      state,
      action: { payload: { countrys: Country[]; graphViewId: string } },
    ) => {
      let graphView = state.graphViews[action.payload.graphViewId];
      graphView.filter.countrys = action.payload.countrys;
    },
    addNewGraphView: (state, action: { payload: GraphView }) => {
      state.graphViews[action.payload.id] = action.payload;
    },
    removeGraphView: (state, action: { payload: { grahViewId: string } }) => {
      delete state.graphViews[action.payload.grahViewId];
    },
    updateGraphView: (
      state,
      action: { payload: { graphViewId: string; graphView: GraphView } },
    ) => {
      state.graphViews[action.payload.graphViewId] = action.payload.graphView;
    },
    setGraph(
      state,
      action: {
        payload: { graphViewId: string; serializedGraph: SerializedGraph; centerNode: string };
      },
    ) {
      let graphView = state.graphViews[action.payload.graphViewId];
      graphView.serializedGraph = action.payload.serializedGraph;
      graphView.centerNode = action.payload.centerNode;
    },
    setToolOpenStatus: (
      state,
      action: {
        payload: {
          savedCases: boolean;
          advancedFilters: boolean;
        };
      },
    ) => {
      state.toolOpenStatus = action.payload;
    },
    incrementRefreshCounter: (state) => {
      state.refreshCounter++;
    },
    setExactSearch: (state, action: { payload: Node[] }) => {
      state.exactSearch = action.payload;
    },
    setContextSearch: (state, action: { payload: Node[] }) => {
      state.contextualSearch = action.payload;
    },
    setSearchMode: (state, action: { payload: SearchMode }) => {
      state.searchMode = action.payload;
    },
    setSearchQuery: (state, action: { payload: string }) => {
      state.searchQuery = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setProjectState,
  // setViewName,
  saveDoc,
  addMessage,
  clearConversation,
  addConversation,
  setOpenConversationId,
  renameConversation,
  removeConversation,
  addFloatingDoc,
  removeFloatingDoc,
  addFolder,
  removeFolder,
  removeDoc,
  setCenterNode,
  setTimeFilter,
  addNewGraphView,
  addImportanceFilter,
  removeImportanceFilter,
  addCaseDetailFilter,
  removeCaseDetailFilter,
  addDecisionLevelFilter,
  removeDecisionLevelFilter,
  addCountryFilter,
  removeCountryFilter,
  setCountryFilter,
  addArticleFilter,
  removeArticleFilter,
  setFilter,
  updateFolder,
  updateGraphView,
  removeGraphView,
  setToolOpenStatus,
  incrementRefreshCounter,
  setExactSearch,
  setContextSearch,
  setSearchMode,
  setSearchQuery,
  setGraph,
} = projectStateSlice.actions;

export default projectStateSlice.reducer;
