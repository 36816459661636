import {
  Accordion,
  Button,
  ButtonGroup,
  Divider,
  Grid,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Stack,
  styled,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import theme from '../theme';
import { useEffect, useState } from 'react';
import { Folder, FolderPlus, Home, Search } from 'react-feather';
import DocumentCard from '../components/DocumentCard';
import { useAppDispatch, useAppSelector } from '../lib/hooks';
import { useAIsearchQuery, useSearchQuery } from '../lib/api/GraphAPI';
import {
  setContextSearch,
  setExactSearch,
  setSearchMode,
  setSearchQuery,
} from '../lib/state/slices/ProjectStateSlice';
import { SearchMode } from '../lib/state/ViewTypes';
import sortNodes from '../lib/Sort';
import { NewProjectModal } from '../components/NewProjectModal';
import { ProjectsOverviewModal } from '../components/ProjectsOverviewModal';
import { NavAccordionItem } from '../components/SidebarItems/NavAccordionItem';
import { NavAccordionItemChild } from '../components/SidebarItems/NavAccordionItemChild';
import { useLocation, useNavigate } from 'react-router-dom';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { selectAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

export default function ResearchPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchMode = useAppSelector((state) => state.viewReducer.searchMode);
  const searchQuery = useAppSelector((state) => state.viewReducer.searchQuery);
  const projectState = useAppSelector((state) => state.viewReducer);
  const newProjectModalDisclosure = useDisclosure();
  const projectsOverviewModalDisclosure = useDisclosure();

  const {
    data: dataAI,
    isLoading: isLoadingAI,
    isSuccess: isSuccessAI,
  } = useAIsearchQuery(searchQuery);
  const { data, isLoading, isSuccess } = useSearchQuery(searchQuery);

  const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
    selectAnatomy.keys,
  );
  const [debouncedQuery, setDebouncedQuery] = useState(searchQuery);
  const [sortOrder, setSortOrder] = useState<'default' | 'date' | 'relevance'>('default');

  let nodes =
    searchMode === SearchMode.EXACT ? projectState.exactSearch : projectState.contextualSearch;

  // Stores relevant results in state
  useEffect(() => {
    if (!searchQuery || searchQuery === '') return;

    if (searchMode === SearchMode.EXACT) {
      if (isSuccess && data) {
        dispatch(setExactSearch(data));
      }
    } else {
      if (isSuccessAI && dataAI) {
        dispatch(setContextSearch(dataAI.nodes));
      }
    }
  }, [searchQuery, isSuccess, isSuccessAI, data, dataAI]);

  // sets DebouncedQuery equal to value in ProjectState when it loads in
  useEffect(() => {
    setDebouncedQuery(searchQuery);
  }, [searchQuery]);

  // Debounced query logic
  useEffect(() => {
    const handler = setTimeout(() => dispatch(setSearchQuery(debouncedQuery)), 2000);
    return () => clearTimeout(handler);
  }, [debouncedQuery]);

  return (
    <Stack className="MAIN" direction="column" rowGap={4} padding={4} width="100%" height="100%">
      <Stack
        className="TOP BAR"
        height="fit-content"
        direction="row"
        justifyContent="space-between"
        width={'100%'}
        padding={8}
        borderRadius="0.375rem"
        background="linear-gradient(0deg, #9F7AEA 0.51%,rgb(222, 201, 251) 97%)"
        backgroundColor={theme.colors.purple[200]}
      >
        <Stack
          className="TOP BAR CONTENT"
          direction={{ base: 'column', lg: 'row' }}
          justifyContent={{ base: 'center', md: 'space-between' }}
          alignItems="center"
          width={'100%'}
          spacing={{ base: 2, md: 6 }}
        >
          <Stack direction="column" className="PROJECT CONTENT" alignItems="center">
            <Stack
              className="PROJECT CONTAINER"
              direction="column"
              justifyContent="center"
              alignItems="center"
              flexWrap="wrap"
              gap={2}
            >
              <Tooltip
                label={
                  <Text>
                    This is your current project:<br></br>"{projectState.name}" <br></br>It is saved
                    every 5 seconds.
                  </Text>
                }
                placement="right"
                openDelay={500}
              >
                <Menu>
                  <MenuButton
                    as={Button}
                    width={'fit-content'}
                    variant="ghost"
                    className="PROJECT NAME"
                    rightIcon={
                      <ChevronDownIcon width={5} height={5} color={theme.colors.blue[700]} />
                    }
                    maxWidth={{ base: '300px', md: '500px', lg: '500px', xl: '800px' }}
                    _hover={{
                      bg: theme.colors.purple[200],
                      animation:
                        'scale-in-center 2.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both',
                    }}
                    _active={{
                      bg: theme.colors.purple[300],
                    }}
                  >
                    <Text
                      fontWeight={700}
                      fontSize="xx-large"
                      color={theme.colors.blue[700]}
                      textAlign={'center'}
                      fontStyle="bold"
                      cursor="default"
                      maxHeight={{ base: '50px', lg: '100px' }}
                      wordBreak={'break-all'}
                      overflow="hidden"
                      textOverflow="ellipsis"
                    >
                      {projectState.name}
                    </Text>
                  </MenuButton>
                  <MenuList width={'200px'}>
                    <NavAccordionItemChild
                      key="new_project_button"
                      text="Create new Project"
                      textShort="New Project"
                      icon={<FolderPlus />}
                      isOpen={true}
                      onClick={newProjectModalDisclosure.onOpen}
                    />
                    <NavAccordionItemChild
                      key="open_project_button"
                      text="Open Project"
                      textShort="Open Project"
                      icon={<Folder />}
                      isOpen={true}
                      onClick={projectsOverviewModalDisclosure.onOpen}
                    />
                    <NewProjectModal
                      isOpen={newProjectModalDisclosure.isOpen}
                      onClose={newProjectModalDisclosure.onClose}
                    />

                    <ProjectsOverviewModal
                      isOpen={projectsOverviewModalDisclosure.isOpen}
                      onClose={projectsOverviewModalDisclosure.onClose}
                    />
                  </MenuList>
                </Menu>
              </Tooltip>
            </Stack>
          </Stack>
          <Stack
            className="SEARCH BAR"
            direction="column"
            alignItems={'flex-start'}
            justifyContent={'center'}
            marginTop={4}
            width="100%"
            maxWidth="600px"
          >
            <InputGroup className="SEARCH BAR INPUT">
              <InputLeftElement>
                <Search />
              </InputLeftElement>
              <Input
                placeholder="Search for a case..."
                variant="filled"
                background="white"
                _focus={{ bg: 'white' }}
                value={debouncedQuery}
                onChange={(e) => setDebouncedQuery(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    dispatch(setSearchQuery(debouncedQuery));
                  }
                }}
              ></Input>
            </InputGroup>
            <Stack className="SEARCH TYPE" direction="row" gap={2} alignItems="center">
              <Text marginLeft={2} fontSize="sm" fontWeight={500} color={theme.colors.white}>
                Search Type:
              </Text>
              <Tooltip
                label="Change the search type to get different results. Exact match will search for cases that are exactly the search query. Contextual search will search for cases that are similar to the search query"
                placement="right"
                openDelay={1000}
              >
                <ButtonGroup isAttached variant="solid" size="sm" borderRadius="0.375rem">
                  <Button
                    onClick={() => dispatch(setSearchMode(SearchMode.CONTEXT))}
                    colorScheme={searchMode === SearchMode.CONTEXT ? 'purple' : 'whiteAlpha'}
                  >
                    Contextual
                  </Button>
                  <Button
                    onClick={() => dispatch(setSearchMode(SearchMode.EXACT))}
                    colorScheme={searchMode === SearchMode.EXACT ? 'purple' : 'whiteAlpha'}
                  >
                    Exact Match
                  </Button>
                </ButtonGroup>
              </Tooltip>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <Stack
        height="100%"
        width="100%"
        borderRadius="0.375rem"
        backgroundColor={theme.colors.gray[100]}
        padding={4}
        overflowY="auto"
        overflowX="hidden"
        rowGap={4}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Text fontWeight={600} color={theme.colors.purple[700]} fontSize="large">
            {!nodes
              ? 'Loading...'
              : // : searchQuery === ''
              // ? 'Waiting for search input'
              nodes.length === 0
              ? 'No results found'
              : 'Results: ' + nodes.length}
          </Text>

          <Stack direction="row">
            <InputGroup>
              <Select
                variant="solid"
                background="white"
                size="sm"
                borderRadius="0.375rem"
                _hover={{ bg: theme.colors.gray[200], transition: 'background-color 0.2s' }}
                _active={{ bg: theme.colors.gray[300], transition: 'background-color 0.2s' }}
                transition="background-color 0.2s"
                onChange={(e) => setSortOrder(e.target.value as 'default' | 'date' | 'relevance')}
                value={sortOrder}
              >
                <option value="default">Best Match</option>
                <option value="date">Sort by date (new-old)</option>
                <option value="relevance">Sort by importance</option>
              </Select>
            </InputGroup>
          </Stack>
        </Stack>

        <Divider
          style={{ width: '100%', margin: 0, borderWidth: '2px', opacity: '80%' }}
          color="darkgray"
          width={1}
          orientation="horizontal"
        />

        <Grid
          padding={4}
          style={{
            height: '100%',
            backgroundColor: theme.colors.gray[100],
            borderRadius: '10px',
            overflowY: 'scroll',
            scrollbarWidth: 'thin',
            justifyItems: 'center',
          }}
          templateColumns={'repeat(auto-fit, minmax(380px, 1fr))'}
          gap={4}
        >
          {sortNodes(nodes, sortOrder).map((node) => (
            <DocumentCard key={node.properties.id} id={node.properties.id} />
          ))}
        </Grid>
      </Stack>
    </Stack>
  );
}
