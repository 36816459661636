import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Flex,
  HStack,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Spinner,
  Stack,
  Tag,
  Text,
  Tooltip,
  VStack,
  theme,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useGetNodeQuery, useGetSummaryQuery } from '../lib/api/GraphAPI';
import { useAppDispatch, useAppSelector } from '../lib/hooks';
import { motion } from 'framer-motion';
import { ImportanceNames } from '../lib/api/APITypes';
import { getDefaultGraphView } from '../lib/GraphFactory';
import { articleTooltips, importanceLevelTooltips, protocolTooltips } from '../strings/tooltips';
import { addNewGraphView, saveDoc } from '../lib/state/slices/ProjectStateSlice';
import { CSSProperties, useEffect, useRef, useState } from 'react';
import { ExternalLink, Folder, MoreVertical, PenTool, Save, X } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import ContextMenu, { ContextMenuButton } from './ContextMenu';
import { AISparcles } from './Icons';

export default function DocumentCard(props: {
  id: string;
  nodeId?: string;
  collapsedState?: 'open' | 'collapsed';
  onClose?: () => void;
  style?: CSSProperties;
}) {
  const [cardState, setCardState] = useState<'open' | 'collapsed'>('collapsed');
  const disclosure = useDisclosure();
  const initialFocusRef = useRef(null);
  const dispatch = useAppDispatch();
  const toast = useToast();
  const {
    data: nodeData,
    error: nodeError,
    isLoading: nodeIsLoading,
    isSuccess: nodeIsSuccess,
  } = useGetNodeQuery(props.id);

  const [animateIcon, setAnimateIcon] = useState(false);
  const [targetPosition, setTargetPosition] = useState({ x: 0, y: 0 });
  const [sourcePosition, setSourcePosition] = useState({ x: 0, y: 0 });

  const [isSaving, setIsSaving] = useState(false);
  const cardRef = useRef(null);

  const handleSave = (event: React.MouseEvent, folderId: string, folderName: string) => {
    setIsSaving(true);
    dispatch(saveDoc({ folderId, nodeId: props.id }));
    event?.stopPropagation();

    toast({
      title: 'Document saved',
      description: `The document has been saved to ${folderName}`,
      status: 'info',
      duration: 3000,
      isClosable: true,
    });

    setTimeout(() => setIsSaving(false), 600);
  };

  const saveButton = document.getElementById('save-icon');

  if (saveButton) {
    const saveRect = saveButton.getBoundingClientRect();
    setSourcePosition({
      x: saveRect.left + saveRect.width / 2,
      y: saveRect.top + saveRect.height / 2,
    });
  }
  const saveAnimation = {
    initial: { opacity: 1, scale: 1, x: 0, y: 0 },
    animate: isSaving
      ? { x: 800, y: -100, opacity: 0, scale: 0.5 }
      : { x: 0, y: 0, opacity: 1, scale: 1 },
    transition: { duration: isSaving ? 0.6 : 0.2, ease: 'easeIn' },
  };

  const {
    isOpen: isZoteroModalOpen,
    onOpen: onZoteroModalOpen,
    onClose: onZoteroModalClose,
  } = useDisclosure();
  const folders = useAppSelector((state) => state.viewReducer.folders);

  useEffect(() => {
    if (isZoteroModalOpen && nodeData) {
      console.log('Adding citation metadata');
      const head = document.head;

      head.insertAdjacentHTML(
        'beforeend',
        `<meta name="citation_title" content="${nodeData.properties.label}"/>` +
          `<meta name="DC.type" content="case" />` +
          `<meta name="citation_date" content="${new Date(
            nodeData.properties.judgementdate!,
          ).toDateString()}"/>` +
          `<meta name="citation_language" content="en"/>` +
          `<meta name="citation_public_url" content="${nodeData.properties.url || ''}"/>`,
      );
      document.dispatchEvent(
        new CustomEvent('ZoteroItemUpdated', { bubbles: true, cancelable: true }),
      );
    }

    if (!isZoteroModalOpen) {
      const head = document.head;
      const metaTags = head.querySelectorAll('meta[name^="citation"], meta[name^="DC"]');
      metaTags.forEach((meta) => head.removeChild(meta));
      document.dispatchEvent(
        new CustomEvent('ZoteroItemUpdated', { bubbles: true, cancelable: true }),
      );
    }
  }, [isZoteroModalOpen, nodeData]);

  const {
    data: summary,
    error: summaryError,
    isLoading: summaryIsLoading,
    isSuccess: summaryIsSuccess,
  } = useGetSummaryQuery(props.id);

  function openInGraph() {
    let newGraphView = getDefaultGraphView(props.id);
    newGraphView.name = nodeData
      ? nodeData.properties.label!!.replace('CASE OF', '')
      : 'Graph ' + props.id;
    dispatch(addNewGraphView(newGraphView));
    navigate('/graph/' + newGraphView.id);
  }
  const navigate = useNavigate();

  const textVariants = {
    open: {
      scroll: 'none',
      height: 'auto',
      maxHeight: '270px',
      opacity: 1,
      y: 0,
    },
    collapsed: {
      opacity: 0,
      y: -10,
      height: 0,
    },
  };

  return (
    <motion.div
      initial={{ opacity: 0, height: '0', x: -100 }}
      animate={{ opacity: 1, height: 'auto', x: 0 }}
      transition={{
        duration: 0.3,
        ease: 'easeInOut',
      }}
      style={{ ...props.style, zIndex: 100, cursor: 'pointer' }}
    >
      <motion.div {...saveAnimation} ref={cardRef}>
        <Card width={'390px'} style={{ boxShadow: '0px 4px 20px -4px #00000080' }}>
          <CardBody
            onClick={() => {
              if (cardState === 'open') setCardState('collapsed');
              else if (cardState === 'collapsed') setCardState('open');
            }}
          >
            <Stack spacing="3">
              <Stack direction={'row'} justifyContent={'space-between'}>
                {nodeIsLoading ? (
                  <Spinner />
                ) : (
                  <Heading size="md" whiteSpace={'normal'}>
                    {nodeData ? nodeData.properties.label : 'Something went wrong'}
                  </Heading>
                )}
                <CardMenu />
              </Stack>
              <CardTags />
              {nodeData ? (
                <HStack spacing={2}>
                  <Text fontSize="14px" fontWeight={600} color={theme.colors.gray[700]}>
                    Date: {new Date(nodeData.properties.judgementdate!).toLocaleDateString('de-DE')}{' '}
                  </Text>
                  <Text fontSize="14px" fontWeight={600} color={theme.colors.gray[700]}>
                    |
                  </Text>
                  <Text fontSize="14px" fontWeight={600} color={theme.colors.gray[700]}>
                    Appno:{' '}
                    {
                      <Tooltip label={nodeData.properties.appno.replaceAll(';', '  ')}>
                        {nodeData.properties.appno.length > 10
                          ? nodeData.properties.appno.substring(0, 10) + '...'
                          : nodeData.properties.appno}
                      </Tooltip>
                    }
                  </Text>
                </HStack>
              ) : (
                <Spinner />
              )}
              {nodeIsLoading ? (
                <Spinner colorScheme="purple" />
              ) : cardState === 'open' ? (
                <div>
                  {' '}
                  <Tooltip label="This content is AI generated. Press the three dots and read the original document for more information.">
                    <HStack>
                      <Icon as={AISparcles} />
                      <Text fontWeight={600} fontSize="14px" color={theme.colors.gray[700]}>
                        AI Summary
                      </Text>
                    </HStack>
                  </Tooltip>
                  <motion.div
                    variants={textVariants}
                    style={{ overflow: 'hidden' }}
                    initial="collapsed"
                    animate={cardState}
                    exit="collapsed"
                    transition={{
                      duration: 0.3,
                      ease: 'easeInOut',
                    }}
                  >
                    <Text
                      fontSize="14px"
                      whiteSpace={'normal'}
                      userSelect="text"
                      onClick={(e) => e.stopPropagation()}
                      cursor={'text'}
                    >
                      {summaryIsLoading ? (
                        <Spinner />
                      ) : summary ? (
                        <>
                          {summary.split('\n').map((line, index) => (
                            <>
                              {line}
                              <br />
                            </>
                          ))}
                        </>
                      ) : (
                        <>No summary available</>
                      )}
                    </Text>
                  </motion.div>
                </div>
              ) : null}
            </Stack>
          </CardBody>
          {cardState === 'open' ? (
            <CardFooter>
              <HStack width="100%" justifyContent={'flex-end'}>
                <Button
                  variant="solid"
                  colorScheme="purple"
                  onClick={(event) => {
                    openInGraph();
                    toast({
                      title: 'Graph opened',
                      colorScheme: 'purple',
                      description: 'Your Graph has been opened in the sidebar',
                      status: 'info',
                      duration: 3000,
                      isClosable: true,
                    });
                  }}
                >
                  Open Graph
                </Button>
              </HStack>
            </CardFooter>
          ) : null}
        </Card>
      </motion.div>

      <Modal
        isOpen={isZoteroModalOpen}
        onClose={onZoteroModalClose}
        closeOnOverlayClick={false}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Zotero Connector</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              You can now use the Zotero connector to cite <br /> {nodeData?.properties.label}
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="purple" onClick={onZoteroModalClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </motion.div>
  );

  function CardTags() {
    return (
      <HStack spacing={1} flexWrap={'wrap'}>
        {nodeData?.properties.importance ? (
          <Tooltip label={importanceLevelTooltips[nodeData?.properties.importance]}>
            <Tag variant={'solid'} bgColor={theme.colors.blue[900]}>
              {ImportanceNames[nodeData?.properties.importance]}
            </Tag>
          </Tooltip>
        ) : null}
        {nodeData?.properties.violation
          ? nodeData?.properties.violation.split(';').map((violation, index) => {
              if (violation.startsWith('p')) {
                return (
                  <Tooltip
                    label={'There has been found a violation of ' + protocolTooltips(violation)}
                    key={violation + index}
                  >
                    <Tag bgColor={theme.colors.red[600]} textColor={'white'}>
                      Protocol {violation}
                    </Tag>
                  </Tooltip>
                );
              }
              return (
                <Tooltip
                  label={
                    'There has been found a violation of ' +
                    articleTooltips[parseInt(violation) - 1]
                  }
                  key={violation + index}
                >
                  <Tag bgColor={theme.colors.red[600]} textColor={'white'}>
                    Article {violation}
                  </Tag>
                </Tooltip>
              );
            })
          : null}
        {nodeData?.properties.nonviolation
          ? nodeData?.properties.nonviolation.split(';').map((nonviolation, index) => {
              if (nonviolation.startsWith('p')) {
                return (
                  <Tooltip
                    label={
                      'There has not been found a violation of ' + protocolTooltips(nonviolation)
                    }
                    key={nonviolation + index}
                  >
                    <Tag bgColor={theme.colors.green[600]} textColor={'white'}>
                      Protocol {nonviolation}
                    </Tag>
                  </Tooltip>
                );
              }
              return (
                <Tooltip
                  label={
                    'There has not been found a violation of ' +
                    articleTooltips[parseInt(nonviolation) - 1]
                  }
                  key={nonviolation + index}
                >
                  <Tag bgColor={theme.colors.green[600]} textColor={'white'}>
                    Article {nonviolation}
                  </Tag>
                </Tooltip>
              );
            })
          : null}
        {nodeData?.properties.keywords
          ? nodeData?.properties.keywords.map((keyword, index) => (
              <Tooltip label={keyword} key={keyword + index}>
                <Tag bgColor={theme.colors.yellow[500]} textColor={'white'}>
                  {keyword.length > 10 ? keyword.substring(0, 10) + '...' : keyword}{' '}
                </Tag>
              </Tooltip>
            ))
          : null}
      </HStack>
    );
  }

  function CardMenu() {
    return (
      <div
        style={{ display: 'flex', flexDirection: 'row' }}
        onClick={(event) => event.stopPropagation()}
      >
        <Popover
          initialFocusRef={initialFocusRef}
          isOpen={disclosure.isOpen}
          onOpen={disclosure.onOpen}
          onClose={disclosure.onClose}
          placement="right"
          offset={[0, 0]}
        >
          <PopoverTrigger>
            <Icon
              boxSize={5}
              padding={'2px'}
              color={theme.colors.purple[700]}
              cursor={'pointer'}
              as={MoreVertical}
            />
          </PopoverTrigger>
          <Portal>
            <PopoverContent width="max-content" padding={0} _focusVisible={{ outline: 'none' }}>
              <ContextMenu onClose={disclosure.onClose}>
                <ContextMenuButton
                  icon={ExternalLink}
                  text={'Open Document'}
                  onClick={() => {
                    window.open(nodeData?.properties.url, '_blank')?.focus();
                  }}
                />
                <div className="relative">
                  {animateIcon && (
                    <motion.div
                      initial={{ x: sourcePosition.x, y: sourcePosition.y, opacity: 1 }}
                      animate={{ x: targetPosition.x, y: targetPosition.y, opacity: 0 }}
                      transition={{ duration: 0.8, ease: 'easeInOut' }}
                      className="absolute z-50"
                      style={{ position: 'fixed', top: 0, left: 0 }}
                    >
                      <Save size={24} color="purple" />
                    </motion.div>
                  )}
                  <ContextMenuButton
                    icon={Save}
                    text="Save to Folder"
                    onClick={() => {}}
                    submenu={
                      <ContextMenu onClose={() => {}}>
                        {folders
                          .slice()
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map((folder) => (
                            <ContextMenuButton
                              key={folder.id}
                              icon={Folder}
                              text={folder.name}
                              onClick={(event) => {
                                if (event) {
                                  handleSave(event, folder.id, folder.name);
                                }
                              }}
                            />
                          ))}
                      </ContextMenu>
                    }
                  />
                </div>

                <ContextMenuButton
                  icon={PenTool}
                  text={'Cite with Zotero'}
                  onClick={() => {
                    disclosure.onClose();
                    onZoteroModalOpen();
                  }}
                />
              </ContextMenu>
            </PopoverContent>
          </Portal>
        </Popover>
        {props.onClose ? (
          <Icon
            onClick={props.onClose}
            cursor={'pointer'}
            boxSize={5}
            as={X}
            color={theme.colors.red[800]}
            marginLeft={'10px'}
          />
        ) : null}
      </div>
    );
  }
}
