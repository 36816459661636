import { Tabs, TabList, Tab, TabPanels, TabPanel, Stack } from "@chakra-ui/react";
import SavedDocumentsList from "./SavedDocumentsList";
import RAGChat from "./RAGChat";
import { motion } from 'framer-motion';
import { useAppDispatch, useAppSelector } from '../../lib/hooks';
import { ChevronRight } from "react-feather";
import {
    incrementRefreshCounter,
    setToolOpenStatus,
} from '../../lib/state/slices/ProjectStateSlice';
export default function Toolbar() {
    const open = useAppSelector((state) => state.viewReducer.toolOpenStatus.savedCases);
    const toolOpenStatus = useAppSelector((state) => state.viewReducer.toolOpenStatus);
    const dispatch = useAppDispatch();

    const variants = {
        open: { width: '420px', minWidth: '420px', maxWidth: '420px', padding: '0px', height: '100%' },
        closed: { width: '30px', minWidth: '30px', maxWidth: '30px', padding: '0px', height: '100%' },
    };
    const expand_button_variants = {
        open: { transform: 'rotate(0deg)' }, // rotate button by 180deg
        closed: { transform: 'rotate(180deg)' },
    };
    const header_variants = {
        open: { opacity: 1, display: 'block', whiteSpace: 'nowrap' }, // disallow linewrap with whitespace: nowrap
        closed: { display: 'none', opacity: 0, whiteSpace: 'nowrap' },
    };

    const content_variants = {
        open: { opacity: 1, flexGrow: 1, overflow: 'auto' }, // disallow linewrap with whitespace: nowrap
        closed: { display: 'none', opacity: 0 },
    }


    return (<motion.div variants={variants} animate={open ? 'open' : 'closed'}>
        <div
            style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'clip',

            }}
        >
            <div style={{height: "100%", overflow: 'hidden' }} >
                <Tabs variant="line" height={'100vh'} colorScheme="purple" display={"flex"} flexDirection={"column"} >
                    <TabList alignItems={'center'} borderColor={'transparent'}>
                        <motion.div variants={expand_button_variants} animate={open ? 'open' : 'closed'}>
                            <ChevronRight
                                onClick={() => {
                                    dispatch(incrementRefreshCounter());
                                    dispatch(setToolOpenStatus({ ...toolOpenStatus, savedCases: !open }));
                                }}
                                style={{ cursor: 'pointer' }}
                            />
                        </motion.div>
                        <Tab borderColor={open ? "inherit" : 'transparent !important'}>Saved Documents</Tab>
                        <Tab>AI Assistent</Tab>
                    </TabList>
                    <motion.div variants={content_variants} animate={open ? 'open' : 'closed'}>
                        <TabPanels overflow={'hidden'} height={'100%'}>
                            <TabPanel padding={0}>
                                <SavedDocumentsList />
                            </TabPanel>
                            <TabPanel height={'100%'} padding={0}>
                                <RAGChat />
                            </TabPanel>
                        </TabPanels>
                    </motion.div>
                </Tabs>
            </div>
        </div>
    </motion.div>)
}