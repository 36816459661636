
import {
  Button,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { ReactElement, useRef } from "react";
import { useAppDispatch } from "../../lib/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import theme from "../../theme";
import { MoreHorizontal, Trash2 } from "react-feather";
import { removeGraphView } from "../../lib/state/slices/ProjectStateSlice";
import ContextMenu, { ContextMenuButton } from '../ContextMenu';

interface nSidebarItemButton {
  text: string;
  icon: ReactElement | undefined
  isActive: boolean;
  isOpen: boolean;
  tabIndex: number;
  onClick: () => void;
}

interface graphItemButton extends nSidebarItemButton {
  viewId: string;
}

export function GraphItem({ ...props }: graphItemButton) {
  const disclosure = useDisclosure();
  const initialFocusRef = useRef(null);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const responsiveValues = {
    buttonPadding: {
      xs: '5',
      lg: '7',
      xl: '7',
    },
    textSize: {
      xs: 'small',
      lg: 'medium',
      xl: 'medium',
    },
  };

  const isActiveColor = theme.colors.purple[300];
  const isExpandedColor = theme.colors.pink[200];
  const isHoverColor = theme.colors.purple[200];

  const backgroundColor = props.isActive ? theme.colors.purple[100] : theme.colors.pink[100];

  // const isActiveColor = theme.colors.purple[300];
  // const isHoverColor = theme.colors.pink[200];
  const isDefaultColor = theme.colors.pink[100];

  return (
    <Stack direction="row" gap={0}>
      <Tooltip label={props.text} openDelay={1000} placement="end">
        <Button
          variant="ghost"
          width="full"
          maxHeight={8}
          minHeight={8}
          borderRightRadius={props.isOpen ? '0' : '0.375rem'}
          pl={6}
          padding={responsiveValues.buttonPadding}
          cursor="pointer"
          onClick={props.onClick}
          // bg={props.isActive ? isActiveColor : isDefaultColor}
          _hover={{ bg: isHoverColor }}
          tabIndex={props.tabIndex}
        >
          <Stack
            direction="row"
            width="full"
            alignItems="center"
            justifyContent={props.isOpen ? 'flex-start' : 'center'}
          >
            <Stack direction="row" overflow="hidden" textOverflow="ellipsis" width="100%">
              <Text
                fontSize="medium"
                textAlign={props.isOpen ? 'left' : 'center'}
                alignContent="center"
                transition="hideBelow 1s"
                width="fit-content"
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
                display={props.isOpen ? 'block' : 'none'}
              >
                {props.text}
              </Text>
            </Stack>
          </Stack>
        </Button>
      </Tooltip>
      {props.isOpen ? (
        <Popover
          initialFocusRef={initialFocusRef}
          isOpen={disclosure.isOpen}
          onOpen={disclosure.onOpen}
          onClose={disclosure.onClose}
          placement="right"
          offset={[0, 0]}
        >
          <PopoverTrigger>
            <IconButton
              size="sm"
              borderLeftRadius={props.isOpen ? '0' : '0.375rem'}
              aria-label="sidebar_button_options"
              aria-hidden={false}
              icon={<MoreHorizontal />}
              tabIndex={props.tabIndex + 1}
              background="transparent"
              // bg={props.isActive ? isActiveColor : isDefaultColor }
              _hover={{ bg: isHoverColor }}
              onClick={() => disclosure.onOpen()}
            />
          </PopoverTrigger>
          <PopoverContent width="fit-content" padding={0}>
              <ContextMenu onClose={disclosure.onClose}>
                <ContextMenuButton icon={Trash2} text="Delete graph" onClick={() => {
                  if (location.pathname === `/graph/${props.viewId}`) {
                    navigate('/research');
                  }
                  disclosure.onClose();
                  dispatch(removeGraphView({ grahViewId: props.viewId }));
                }} />
              </ContextMenu>
          </PopoverContent>
        </Popover>
      ) : undefined}
    </Stack>
  );
}