import { useAuth } from 'oidc-react';
import { useLazyGetActiveProjectStateQuery } from './api/GraphAPI';
import { setProjectState } from './state/slices/ProjectStateSlice';
import { store } from './state/Store';
import { useEffect } from 'react';

export default function LoadAppStateFromServer() {
  
  const [trigger, result, lastPromiseInfo] = useLazyGetActiveProjectStateQuery();

  const auth = useAuth();

  useEffect(() => {
    trigger();
    if (result.isSuccess && result.data && result.data.length > 0) {
      store.dispatch(setProjectState(result.data[0].json));
    }
  }, [auth.userData?.profile.sub, result.isSuccess, result.data]);
  return null;
}
