export const importanceLevelTooltips = [
  'EurLex Cases',
  "Key cases are judgments delivered since the inception of the new court in 1998 which have been selected and set as the official 'case-law' in the Case Reports.",
  'Level 1 = High importance, meaning all judgements which had made a significant contribution to the development of case-law, but has not been included in the Case Reports.',
  'Level 2 = Medium importance, meaning other judgments that, while not making a significant contribution to the case-law, they go beyond merely applying existing case law.',
  'Level 3 = Low importance, meaning judgments of very little legal interest. This could be judgments that simply apply existing case-law, friendly settlements and strike-outs.',
];
export const caseDetailTooltips = [
  'Violation = Judgments where the Court has found a violation of the Convention or a Protocol Article.',  
  'Non-Violation = Judgments where the Court has not found a violation of the Convention or a Protocol Article.',
  'Both = Judgments where the Court has found a violation of the Convention or a Protocol Article, but also found a non-violation.',
  'Miscellaneous = Judgments where there has neither been found a violation, or a non-violation. This could for example be a friendly settlement, strike-out or just satisfaction.',
  'Saved in List = Judgments you have saved. These can be found in the list on the left-hand sidebar.',
];

export const articleTooltips = [
  'Article 1: Obligation to respect human rights',
  'Article 2: Right to life',
  'Article 3: Prohibition of torture',
  'Article 4: Prohibition of slavery and forced labour',
  'Article 5: Right to liberty and security',
  'Article 6: Right to a fair trial',
  'Article 7: No punishment without law',
  'Article 8: Right to respect for private and family life',
  'Article 9: Freedom of thought, conscience and religion',
  'Article 10: Freedom of expression',
  'Article 11: Freedom of assembly and association',
  'Article 12: Right to marry',
  'Article 13: Right to an effective remedy',
  'Article 14: Prohibition of discrimination',
  'Article 15: Derogation in time of emergency',
  'Article 16: Restrictions on political activity of aliens',
  'Article 17: Prohibition of abuse of rights',
  'Article 18: Limitation on use of restrictions of rights',
];

export function protocolTooltips(protocol) {
  switch (protocol) {
    case 'p1-1':
      return 'Article 1 of Protocol No. 1: Protection of property';
    case 'p1-2':
      return 'Article 2 of Protocol No. 1: Right to education';
    case 'p1-3':
      return 'Article 3 of Protocol No. 1: Right to free elections';
    case 'p4-2':
      return 'Article 2 of Protocol No. 4: Freedom of movement';
    case 'p6-1':
      return 'Article 1 of Protocol No. 6: Abolition of the death penalty';
    case 'p7-2':
      return 'Article 2 of Protocol No. 7: Right of appeal in criminal matters';
    case 'p7-3':
      return 'Article 3 of Protocol No. 7: Compensation for wrongful conviction';
    case 'p12-1':
      return 'Article 1 of Protocol No. 12: General prohibition of discrimination';
    default:
      return 'No description available for this protocol';
  }
}
