import { ReactElement } from 'react';
import Sidebar from '../components/Sidebar';
import { Box, useDisclosure } from '@chakra-ui/react';
import  Toolbar from '../components/Toolbar/Toolbar';

export default function PageWithSidebar(props: { children: ReactElement }) {
  
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });
  
  return (
    <Box
      display="flex"
      flexDirection="row"
      height="100%"
      width="100%"
      overflow="hidden"
      >
      <Sidebar isOpen={isOpen} onToggle={onToggle} />
      {props.children}
      <Toolbar />
    </Box>
    // <div style={{ display: 'flex', flexDirection: 'row', height: '100%', width: '100%', overflow: 'hidden' }}>
    // </div>
  );
}
