
import {
  Button,
  Stack,
  Text,
  Tooltip,
  useBreakpointValue,
} from '@chakra-ui/react';
import theme from '../../theme';
import { ReactElement } from 'react';

type NavAccordionItemChildProps = {
  text: string;
  textShort: string;
  icon: ReactElement;
  isOpen: boolean;
  onClick: () => void;
}

export function NavAccordionItemChild({ ...props }: NavAccordionItemChildProps) {
  const text = useBreakpointValue({ base: props.textShort, lg: props.text });

  const responsiveValues = {
    buttonPadding: {
      xs: '5',
      lg: '7',
      xl: '7',
    },
    textSize: {
      xs: 'small',
      lg: 'medium',
      xl: 'medium',
    },
  };

  return (
    <Button
      leftIcon={props.icon}
      pl={6}
      variant="ghost"
      width="full"
      maxHeight="8"
      minHeight="8"
      onClick={props.onClick}
      _hover={{
        bg: theme.colors.purple[200],
      }}
    >
      <Stack
        direction="row"
        width="full"
        alignItems="center"
        justifyContent={props.isOpen ? 'flex-start' : 'center'}
      >
        <Tooltip label={props.text} openDelay={1000} placement="end">
          <Text
            fontSize="medium"
            textAlign={props.isOpen ? 'left' : 'center'}
            alignContent="center"
            transition="hideBelow 1s"
            width={180}
            fontWeight={600}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            textColor="black"
            display={props.isOpen ? 'block' : 'none'}
          >
            {text}
          </Text>
        </Tooltip>
      </Stack>
    </Button>
  );
}