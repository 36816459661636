
import {
  Button,
  Card,
  CardBody,
  Divider,
  Grid,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Spacer,
  Stack,
  Text,
  theme,
  Tooltip,
} from '@chakra-ui/react';
import {
  useLazyGetAllProjectStatesQuery,
  useRemoveProjectStateMutation,
  useSaveProjectStateMutation,
  useSetActiveProjectStateMutation,
} from '../lib/api/GraphAPI';
import { useEffect, useState } from 'react';
import { HelpCircle, MoreHorizontal } from 'react-feather';
import { setProjectState } from '../lib/state/slices/ProjectStateSlice';
import { useAppDispatch, useAppSelector } from '../lib/hooks';
import ProjectState from '../lib/state/ViewTypes';
import { useNavigate } from 'react-router-dom';
import { format, parse } from "date-fns";

export const ProjectsOverviewModal = (props: { isOpen: boolean; onClose: () => void }) => {
  
  const [trigger, result, lastPromiseInfo] = useLazyGetAllProjectStatesQuery();
  const [selectedProjectState, setSelectedProjectState] = useState<number | null>(null);
  const [saveProjectState] = useSaveProjectStateMutation();
  const [removeProjectState] = useRemoveProjectStateMutation();
  const [setActive] = useSetActiveProjectStateMutation();
  
  const state = useAppSelector((state) => state.viewReducer);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  
  const isSuccess = result.isSuccess && result.data && result.data.length > 0;
  
  
  const handleClose = () => {
    setSelectedProjectState(null);
    props.onClose();
  }
  
  const handleSubmit = () => {
    
    console.log(isSuccess, selectedProjectState);
    
    if (isSuccess && selectedProjectState !== null) {   
         
      if (state.isAutosaveActive) {
        console.log("autosaved via Modal")
        saveProjectState(state);
      }
      
      const newProjectState: ProjectState = result.data[selectedProjectState].json;
      
      navigate('/research');
      
      dispatch(setProjectState(newProjectState));
      setActive(newProjectState.id);
      
      handleClose();
    }
  }
  
  const handleDelete = (projectStateId: string) => {
    if (projectStateId !== "") {
      console.log("Deleting project...");
      removeProjectState(projectStateId);
      trigger();
    }
  }
  
  useEffect(() => {
    if (props.isOpen) {
      console.log("Projects Overview Modal opened")
      trigger()
    }
  }, [props.isOpen])
  
  return (
    <Modal isOpen={props.isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Stack direction="row" alignItems="center">
            <Text>Select a project</Text>
            <Tooltip
              label="A project contains your graphs, folders and search information"
              placement="right"
            >
              <HelpCircle width={20} height={20} />
            </Tooltip>
          </Stack>
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <Grid templateColumns="repeat(1, 1fr)" width="full" gap={2}>
            {isSuccess ? (
              [...result.data]
              .map((e, i) => {
                const isSelected = i === selectedProjectState;
                const isCurrent = e.json.id === state.id;
                const dateTimeObj = parse(e.last_modified, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx", new Date());
                const dateTime = format(dateTimeObj, 'dd. MMM yyyy HH:mm');
                return (
                  <Card
                    key={'card_' + e.json.id}
                    variant="outline"
                    transition="background 200ms ease-in-out"
                    _hover={{
                      bg: theme.colors.purple[200],
                    }}
                    background={isSelected ? theme.colors.purple[100] : ''}
                    borderColor={isSelected ? theme.colors.gray[400] : theme.colors.gray[200]}
                  >
                    <CardBody
                      key={'card_body_' + e.json.id}
                      onClick={() => setSelectedProjectState(i)}
                    >
                      <Stack
                        key={'card_stack_top_' + e.json.id}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Text
                          key={'card_text_name_' + e.json.id}
                          fontWeight={isCurrent ? 'bold' : ''}
                        >
                          {e.json.name} {isCurrent ? '(Current)' : ''}
                        </Text>

                        <Popover key={'card_popover_' + e.json.id} placement="right">
                          <PopoverTrigger key={'card_popover_trigger_' + e.json.id}>
                            <IconButton
                              key={'card_popover_trigger_button' + e.json.id}
                              size="sm"
                              variant="ghost"
                              aria-label={`project_state_${e.json.id}`}
                              aria-hidden={false}
                              icon={<MoreHorizontal />}
                              onClick={() => {}}
                            />
                          </PopoverTrigger>

                          <PopoverContent
                            key={'card_popover_content' + e.json.id}
                            width="fit-content"
                          >
                            <PopoverBody key={'card_popover_content_body' + e.json.id}>
                              <Stack direction="column" key={'card_popover_stack' + e.json.id}>
                                <Tooltip
                                  label={isCurrent ? 'Cannot delete project while inside it' : ''}
                                  shouldWrapChildren
                                >
                                  <Button
                                    key={'card_popover_delete_button' + e.json.id}
                                    onClick={() => handleDelete(e.json.id)}
                                    disabled={isCurrent}
                                  >
                                    Delete Project
                                  </Button>
                                </Tooltip>
                              </Stack>
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      </Stack>

                      <Divider
                        key={'card_divider_' + e.json.id}
                        style={{
                          width: '100%',
                          margin: 0,
                          marginTop: 2,
                          borderWidth: 1,
                          opacity: '80%',
                        }}
                        width={1}
                        orientation="horizontal"
                      />
                      <Spacer key={'card_spacer_' + e.json.id} height="6" />

                      <Stack
                        key={'card_stack_bottom_' + e.json.id}
                        direction="row"
                        justifyContent="space-between"
                      >
                        <Text key={'card_text_graphs_' + e.json.id}>
                          Total Graphs: {Object.keys(e.json.graphViews).length}
                        </Text>
                        <Text key={'card_text_date_' + e.json.id}>{dateTime}</Text>
                      </Stack>
                    </CardBody>
                  </Card>
                );
              })
            ) : (
              <></>
            )}
          </Grid>
        </ModalBody>
        <ModalFooter>
          <Stack direction="row" width="full" justifyContent="end">
            <Button variant="outline" onClick={handleClose}>
              Cancel
            </Button>
            <Tooltip label={selectedProjectState === null ? "Please select a project" : ""} shouldWrapChildren>
              <Button variant="solid" onClick={handleSubmit} disabled={selectedProjectState === null}>
                Open Project
              </Button>
            </Tooltip>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
