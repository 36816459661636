

import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Button,
  Icon,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import theme from '../../theme';
import { ChevronDown, ChevronUp } from 'react-feather';
import { CSSProperties } from 'react';

type navAccordionItemProps = {
  title: string;
  icon: any;
  isOpen: boolean;
  isActive: boolean;
  onClick: () => void;
  children: any[];
};

export function NavAccordionItem({ ...props }: navAccordionItemProps) {
  const isActiveColor = theme.colors.purple[300];

  const defaultColor = theme.colors.purple[200];
  const isHoverColor = theme.colors.purple[200];
  const backgroundColor = theme.colors.purple[100];

  return (
    <AccordionItem p={0} border="none">
     
      {({ isExpanded }) => (
        <> 
          <Stack direction="row" gap={0} overflow={'visible !important'}>
            <Button
              variant="ghost"
              width="full"
              maxHeight="8"
              minHeight="8"
              cursor="pointer"
              borderRightRadius={props.isOpen ? '0' : '0.375rem'}
              onClick={props.onClick}
              bg={props.isActive ? isActiveColor : isExpanded ? defaultColor : ''}
              _hover={{ bg: isHoverColor }}
            >
              <Stack
                direction="row"
                width="full"
                alignItems="center"
                justifyContent={props.isOpen ? 'flex-start' : 'center'}
              >
                <Tooltip placement="end">
                  <Stack
                    direction="row"
                    width="full"
                    justifyContent={props.isOpen ? 'start' : 'center'}
                  >
                    <Icon height={6} width={6}>
                      {props.icon}
                    </Icon>
                    {props.isOpen ? (
                      <Text
                        textAlign="left"
                        alignContent="center"
                        transition="hideBelow 1s"
                        fontWeight={600}
                        display={props.isOpen ? 'block' : 'none'}
                      >
                        {props.title}
                      </Text>
                    ) : undefined}
                  </Stack>
                </Tooltip>
              </Stack>
            </Button>

            {props.isOpen ? (
              <AccordionButton
                m={0}
                p={0}
                minWidth={8}
                maxWidth={8}
                height={8}
                justifyContent="center"
                borderRadius="0.375rem"
                borderLeftRadius={props.isOpen ? '0' : '0.375rem'}
                aria-label="sidebar_button_options"
                aria-hidden={false}
                bg={props.isActive ? isActiveColor : isExpanded ? defaultColor : ''}
                _hover={{ bg: isHoverColor }}
              >
                {isExpanded ? <ChevronUp /> : <ChevronDown />}
              </AccordionButton>
            ) : undefined}
          </Stack>

          <AccordionPanel
            p={0}
            // background="gray.200"
            background={backgroundColor}
            borderTop="null"
            borderRadius="6"
          >
            
            <Stack direction="column" gap={0}>
              {props.children !== null && props.children.length !== 0 ? (
                props.children
              ) : (
                <Text p={2} pl={6} fontSize="md" fontWeight="">
                  Nothing here yet
                </Text>
              )}
            </Stack>
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  );
}